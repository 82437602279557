<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="trip.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <input type="file" @change="onChange" :disabled="trip.account_id == null"/>
      </div>      
      <table class="table">
        <thead>
          <tr>
            <th>Trip Date</th>
            <th>Route</th>
            <th>Vehicle</th>
            <th>Driver</th>
            <th>Estimation Start</th>
            <th>Estimation Finish</th>
            <th>Total Penumpang</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="details in trip.trip_datas" :key="details">
            <td>{{details.trip_date}}</td>
            <td>{{details.route}}</td>
            <td>{{details.vehicle}}</td>
            <td>{{details.driver}}</td>
            <td>{{details.estimation_start}}</td>
            <td>{{details.estimation_finish}}</td>
            <td>{{details.total_penumpang}}</td>
          </tr>
        </tbody>
      </table>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <input class="btn btn-secondary ml-1" value="Download Template" @click.prevent="download_template">
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  import moment from 'moment';
  import * as XLSX from "xlsx"
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('routes', ['routes']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('drivers', ['drivers']),
      ...mapState('trips', ['onRequest']),
    },
    components:{
      Select2
    },
    data(){
      return {
        trip: {
          account_id: null,
          trip_datas: []
        },
        accountOptions: []
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add_bulks(this.trip)
      },
      ...mapActions('trips', ['add_bulks']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.getVehicles({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.trip_datas = []
      },
      download_template(){
        window.open('/template_trips.xlsx')
      },
      onChange(event){
        this.file = event.target.files ? event.target.files[0] : null;
        if (this.file) {
          const reader = new FileReader();

          reader.onload = () => {
            this.arrayBuffer = reader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {
              type: "binary"
            });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let res = XLSX.utils.sheet_to_json(worksheet, {
              raw: false
            })
            let arrs = []
            res.map(item => {
              if(!item["Trip Date"] || item['Trip Date'] === ''){
                return null;
              }
              let a = {account_id: this.trip.account_id, route: '', route_id: null, vehicle: '', vehicle_id: null, driver: '', driver_id: null, trip_date: null, total_penumpang: 0, status: 0, estimation_start: null, estimation_finish: null}
              a.trip_date = item["Trip Date"].trim()
              if(item['Total Penumpang'] && item['Total Penumpang'] !== ''){
                a.total_penumpang = parseInt(item["Total Penumpang"].trim())
              }
              if(item["Estimation Start Date"] && item["Estimation Start Date"] !== '' && item["Estimation Start Time"] && item["Estimation Start Time"] !== ''){
                a.estimation_start = moment(item["Estimation Start Date"]).format("YYYY-MM-DD") + " " + item["Estimation Start Time"]
                a.estimation_start = moment(a.estimation_start).format("YYYY-MM-DD HH:mm")
              }
              if(item["Estimation Finish Date"] && item["Estimation Finish Date"] !== '' && item["Estimation Finish Time"] && item["Estimation Finish Time"] !== ''){
                a.estimation_finish = moment(item["Estimation Finish Date"]).format("YYYY-MM-DD") + " " + item["Estimation Finish Time"]
                a.estimation_finish = moment(a.estimation_finish).format("YYYY-MM-DD HH:mm")
              }
              let routes = this.routes.filter(item2 => item2.route_name == item.Route.trim())
              if(routes.length > 0){                
                a.route = routes[0].route_name
                a.route_id = routes[0].id
              } else {
                return null;
              }
              if(item.Vehicle && item.Vehicle !== ''){
                let vehicles = this.vehicles.filter(item2 => item2.vehicle_no == item.Vehicle.trim())
                if(vehicles.length > 0){
                  a.vehicle = vehicles[0].vehicle_no
                  a.vehicle_id = vehicles[0].id
                }
              }
              if(item.Driver && item.Driver !== ''){
                let drivers = this.drivers.filter(item2 => item2.name == item.Driver.trim())
                if(drivers.length > 0){
                  a.driver = drivers[0].name
                  a.driver_id = drivers[0].id
                }
              }
              arrs.push(a)
            })
            this.trip.trip_datas = arrs
          }

          reader.readAsArrayBuffer(this.file);
        }
      },
    },
    created() {
      this.$emit('onChildInit', 'Upload Trip')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },      
    }
  }
</script>

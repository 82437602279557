<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="trip.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" v-model.number="trip.route_id" :options="routeOptions"  @change="onChangeRoute($event)" @select="onChangeRoute($event)"/>
      </div>
      <div class="form-group col-sm-12 required" v-if="selected_route && selected_route.route_direction == 3">
        <label for="route_id">Sub Route:</label>
        <select class="form-control" required name="sub_route_id" v-model.number="trip.sub_route_id">
          <option value="">--- Please Select One ---</option>
          <option v-for="r in sub_routes" :key="r" :value="r.id">{{r.sub_route_name}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="trip.vehicle_id" :options="vehicleOptions" @change="onChangeVehicle($event)" @select="onChangeVehicle($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 ">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="trip.driver_id" :options="driverOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="trip_date">Trip Date:</label>
        <input class="form-control" name="date" type="date" required v-model="trip.trip_date" @blur="onChangeDate()">
      </div>
      <div class="form-group col-sm-12 col-md-12 ">
        <label for="road_warrant_id">Road Warrant:</label>
        <Select2 name="road_warrant_id" v-model.number="trip.road_warrant_id" :options="roadWarrantOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="estimation_trip_start_date">Estimation Start Date:</label>
        <input class="form-control" name="estimation_trip_start_date" type="date" required v-model="estimation_start_date">
      </div>
      <TimeComponent :label="'Estimation Start Time'" :selected_time="estimation_start_time" v-on:selected-time="setStart"/>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="estimation_trip_start_date">Estimation Finish Date:</label>
        <input class="form-control" name="estimation_trip_start_date" type="date" required v-model="estimation_finish_date">
      </div>
      <TimeComponent :label="'Estimation Finish Time'" :selected_time="estimation_finish_time" v-on:selected-time="setFinish"/>
      <div class="form-group col-sm-6 col-md-6 required">
        <label for="status">Total Penumpang:</label>
        <input class="form-control" name="total_penumpang" type="number" required v-model.number="trip.total_penumpang">
      </div>
      <div class="form-group col-sm-6 col-md-6 required">
        <label for="status">Penumpang Rit 1:</label>
        <input class="form-control" name="penumpang1" type="number" required v-model.number="trip.penumpang1">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="male">Male Passenger:</label>
        <input class="form-control" name="male" type="number" v-model.number="trip.male">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="female">Female Passenger:</label>
        <input class="form-control" name="female" type="number" v-model.number="trip.female">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="status">Total Revenue:</label>
        <input class="form-control" name="total_revenue" type="number" v-model.number="trip.total_revenue">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="status">Revenue Rit 1:</label>
        <input class="form-control" name="revenue1" type="number" v-model.number="trip.revenue1">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="status">Status:</label>
        <select class="form-control" name="status" disabled v-model.number="trip.status">
          <option value="0" style="color:black;">Draft</option>
        </select>
      </div>
      <InputImage label="Picture" v-on:selected-image="setImage"/>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/trips">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import TimeComponent from '../components/TimeComponent.vue'
  import InputImage from '../components/InputImage.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('routes', ['routes']),
      ...mapState('sub_routes', ['sub_routes']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('road_warrants', ['road_warrants']),
      ...mapState('drivers', ['drivers']),
      ...mapState('trips', ['onRequest']),
    },
    components:{
      TimeComponent,
      InputImage,
      Select2
    },
    data(){
      return {
        trip: {
          account_id: '',
          route_id: '',
          sub_route_id: '',
          vehicle_id: '',
          driver_id: '',
          trip_date: moment().format("YYYY-MM-DD"),
          status: 0,
          total_penumpang: 0,
          penumpang1: 0,
          total_revenue: null,
          revenue1: null,
          road_warrant_id: '',
          male: null,
          female: null,
        },
        estimation_start_date: moment().format("YYYY-MM-DD"),
        estimation_finish_date: moment().format("YYYY-MM-DD"),
        estimation_start_time: '07:00',
        estimation_finish_time: '16:00',
        selected_route: null,
        file_image: null,
        accountOptions: [],
        routeOptions: [],
        vehicleOptions: [],
        driverOptions: [],
        roadWarrantOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.trip.estimation_start = this.estimation_start_date + " " + this.estimation_start_time
        this.trip.estimation_finish = this.estimation_finish_date + " " + this.estimation_finish_time
        this.trip.file_image = this.file_image
        this.add(this.trip)
      },
      ...mapActions('trips', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('sub_routes', {getSubRoutes: 'get_sub_route_by_route'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_route'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      ...mapActions('road_warrants', {getDataByVehicle: 'get_road_warrant_by_vehicle'}),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.trip.route_id = ''
        this.trip.sub_route_id = ''
        this.trip.vehicle_id = ''
        this.trip.driver_id = ''
        this.selected_route = null
      },
      onChangeRoute(event){
        this.getVehicles({route_id: parseInt(event.id)})
        this.getSubRoutes({route_id: parseInt(event.id)})
        this.trip.vehicle_id = ''
        this.trip.sub_route_id = ''
        this.selected_route = null
        let sel = this.routes.filter(item => item.id == event.id)
        if(sel.length > 0){
          this.selected_route = sel[0]
        }
      },
      onChangeVehicle(event){
        this.getDataByVehicle({vehicle_id: parseInt(event.id), date: this.trip.trip_date})
        this.trip.road_warrant_id = ''
      },
      setStart(val){
        this.estimation_start_time = val
      },
      setFinish(val){
        this.estimation_finish_time = val
      },
      setImage(image){
        this.file_image = image
      },
      onChangeDate(){        
        this.trip.road_warrant_id = ''
        if(this.trip.vehicle_id === ''){
          return;
        }
        this.getDataByVehicle({vehicle_id: this.trip.vehicle_id, date: this.trip.trip_date})
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Trip')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
      road_warrants(nextState, prevState){
        if(nextState !== prevState){
          this.roadWarrantOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.roadWarrantOptions.push({id:item.id, text:item.road_warrant_code})
            })
          }
          return
        }
      },
    }
  }
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Schedule Name:</label>
        <input class="form-control" required="" name="schedule_name" type="text" v-model="trip_schedule.schedule_name">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="trip_schedule.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" required v-model.number="trip_schedule.route_id" :options="routeOptions"  @change="onChangeRoute($event)" @select="onChangeRoute($event)"/>
      </div>
      <div class="form-group col-sm-12 required" v-if="selected_route && selected_route.route_direction == 3">
        <label for="route_id">Sub Route:</label>
        <select class="form-control" required name="sub_route_id" v-model.number="trip_schedule.sub_route_id">
          <option value="">--- Please Select One ---</option>
          <option v-for="r in sub_routes" :key="r" :value="r.id">{{r.sub_route_name}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="start_date">Start Date:</label>
        <input class="form-control" name="date" type="date" required v-model="trip_schedule.start_date">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="finish_date">End Date:</label>
        <input class="form-control" name="finish_date" type="date" v-model="trip_schedule.finish_date">
        <small>*Leave Blank to Create Unlimited Schedule</small>
      </div>
      <div class="form-group col-sm-12" style="border-top: 1px solid black;margin-top:10px;padding-top:10px;">
        <div class="row">
          <h4 class="col-sm-12">Time Schedule</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button @click.prevent="addClick()" class="btn btn-success">Add Time Schedule</button>
          </div>
        </div>
        <div class="row" v-for="detail in trip_schedule.trip_schedule_details" :key="detail">
          <div class="form-group col-sm-3 col-md-3 required">
            <label>Day</label>
            <select class="form-control" name="sub_route_id" v-model.number="detail.day">
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
              <option value="0">Sunday</option>
            </select>
          </div>
          <div class="form-group col-sm-3 col-md-3">
            <TimeComponent :custom_header="true" :label="'Estimation Start Time'" :selected_time="detail.estimation_start" v-on:selected-time="(...args)=>this.setStart([detail,...args])"/>
          </div>
          <div class="form-group col-sm-3 col-md-3">
            <TimeComponent :custom_header="true" :label="'Estimation Finish Time'" :selected_time="detail.estimation_finish" v-on:selected-time="(...args)=>this.setFinish([detail,...args])"/>
          </div>
          <div class="form-group col-sm-3 col-md-3 text-right">
            <button @click.prevent="removeTime(detail)" class="btn btn-danger">Remove</button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12" style="border-top: 1px solid black;margin-top:10px;padding-top:10px;">
        <div class="row">
          <h4 class="col-sm-12">Assigned Vehicle & Driver</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button @click.prevent="addClickVehicle()" class="btn btn-success">Add Vehicle & Driver</button>
          </div>
        </div>
        <div class="row" v-for="detail in trip_schedule.trip_schedule_vehicle_drivers" :key="detail">          
          <div class="form-group col-sm-4 required">
            <label for="vehicle_id">Vehicle:</label>
            <Select2 name="vehicle_id" required v-model.number="detail.vehicle_id" :options="vehicleOptions" />
          </div>
          <div class="form-group col-sm-4">
            <label for="driver_id">Driver:</label>
            <Select2 name="driver_id" v-model.number="detail.driver_id" :options="driverOptions" />
          </div>
          <div class="form-group col-sm-4 text-right">
            <button @click.prevent="removeVehicle(detail)" class="btn btn-danger">Remove</button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/trip_schedules">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  import TimeComponent from '../components/TimeComponent.vue'
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('routes', ['routes']),
      ...mapState('sub_routes', ['sub_routes']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('drivers', ['drivers']),
      ...mapState('trip_schedules', ['onRequest']),
    },
    components:{
      Select2,
      TimeComponent,
    },
    data(){
      return {
        trip_schedule: {
          schedule_name: '',
          account_id: '',
          route_id: '',
          sub_route_id: '',
          start_date: moment().add(1, 'day').format("YYYY-MM-DD"),
          finish_date: null,
          trip_schedule_details:[
            {id:null, day: 1, estimation_start: null, estimation_finish: null},
            {id:null, day: 2, estimation_start: null, estimation_finish: null},
            {id:null, day: 3, estimation_start: null, estimation_finish: null},
            {id:null, day: 4, estimation_start: null, estimation_finish: null},
            {id:null, day: 5, estimation_start: null, estimation_finish: null},
            {id:null, day: 6, estimation_start: null, estimation_finish: null},
            {id:null, day: 7, estimation_start: null, estimation_finish: null},
          ],
          trip_schedule_vehicle_drivers:[],
        },
        selected_route: null,
        accountOptions: [],
        routeOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add(this.trip_schedule)
      },
      addClick(){
        this.trip_schedule.trip_schedule_details.push({id:null, day: 1, estimation_start: null, estimation_finish: null})
      },
      addClickVehicle(){
        this.trip_schedule.trip_schedule_vehicle_drivers.push({vehicle_id:null, driver_id: null})
      },
      ...mapActions('trip_schedules', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('sub_routes', {getSubRoutes: 'get_sub_route_by_route'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_route'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.trip_schedule.route_id = ''
        this.trip_schedule.sub_route_id = ''
        this.trip_schedule.vehicle_id = ''
        this.trip_schedule.driver_id = ''
        this.selected_route = null,
        this.trip_schedule.trip_schedule_details = [
          {id:null, day: 1, estimation_start: null, estimation_finish: null},
          {id:null, day: 2, estimation_start: null, estimation_finish: null},
          {id:null, day: 3, estimation_start: null, estimation_finish: null},
          {id:null, day: 4, estimation_start: null, estimation_finish: null},
          {id:null, day: 5, estimation_start: null, estimation_finish: null},
          {id:null, day: 6, estimation_start: null, estimation_finish: null},
          {id:null, day: 7, estimation_start: null, estimation_finish: null},
        ],
        this.trip_schedule.trip_schedule_vehicle_drivers = []
      },
      onChangeRoute(event){
        this.getVehicles({route_id: parseInt(event.id)})
        this.getSubRoutes({route_id: parseInt(event.id)})
        this.trip_schedule.vehicle_id = ''
        this.trip_schedule.sub_route_id = ''
        this.selected_route = null
        let sel = this.routes.filter(item => item.id == event.id)
        if(sel.length > 0){
          this.selected_route = sel[0]
        }
        this.trip_schedule.trip_schedule_details = [
          {id:null, day: 1, estimation_start: null, estimation_finish: null},
          {id:null, day: 2, estimation_start: null, estimation_finish: null},
          {id:null, day: 3, estimation_start: null, estimation_finish: null},
          {id:null, day: 4, estimation_start: null, estimation_finish: null},
          {id:null, day: 5, estimation_start: null, estimation_finish: null},
          {id:null, day: 6, estimation_start: null, estimation_finish: null},
          {id:null, day: 7, estimation_start: null, estimation_finish: null},
        ],
        this.trip_schedule.trip_schedule_vehicle_drivers = []
      },
      setStart(args){
        args[0].estimation_start = args[1]
      },
      setFinish(args){
        args[0].estimation_finish = args[1]
      },
      removeTime(detail){
        const index = this.trip_schedule.trip_schedule_details.indexOf(detail);
        if(index > -1){
          this.trip_schedule.trip_schedule_details.splice(index, 1)
        }
      },
      removeVehicle(detail){
        const index = this.trip_schedule.trip_schedule_vehicle_drivers.indexOf(detail);
        if(index > -1){
          this.trip_schedule.trip_schedule_vehicle_drivers.splice(index, 1)
        }
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Trip Schedule')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>

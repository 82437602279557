<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Schedule Name:</label>
        <input class="form-control" required="" name="schedule_name" type="text" v-model="trip_schedule.schedule_name">
      </div>
      <div v-if="trip_schedule.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="trip_schedule.account.account_name">
      </div>
      <div v-if="trip_schedule.route" class="form-group col-sm-12">
          <label for="route_id">Route:</label>
          <input class="form-control" disabled name="route_id" type="text" v-model="trip_schedule.route.route_name">
      </div>
      <div v-if="trip_schedule.sub_route" class="form-group col-sm-12">
          <label for="route_id">Sub Route:</label>
          <input class="form-control" disabled name="route_id" type="text" v-model="trip_schedule.sub_route.sub_route_name">
      </div>      
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="start_date">Start Date:</label>
        <input class="form-control" name="date" type="date" required v-model="trip_schedule_start_date">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="finish_date">End Date:</label>
        <input class="form-control" name="finish_date" type="date" v-model="trip_schedule_finish_date">
        <small>*Leave Blank to Create Unlimited Schedule</small>
      </div>
      <div class="form-group col-sm-12" style="border-top: 1px solid black;margin-top:10px;padding-top:10px;">
        <div class="row">
          <h4 class="col-sm-12">Time Schedule</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button @click.prevent="addClick()" class="btn btn-success">Add Time Schedule</button>
          </div>
        </div>
        <div class="row" v-for="detail in trip_schedule.trip_schedule_details" :key="detail">
          <div class="form-group col-sm-3 col-md-3 required">
            <label>Day</label>
            <select class="form-control" name="sub_route_id" v-model.number="detail.day">
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
              <option value="0">Sunday</option>
            </select>
          </div>
          <div class="form-group col-sm-3 col-md-3">
            <TimeComponent :custom_header="true" :label="'Estimation Start Time'" :selected_time="detail.estimation_start" v-on:selected-time="(...args)=>this.setStart([detail,...args])"/>
          </div>
          <div class="form-group col-sm-3 col-md-3">
            <TimeComponent :custom_header="true" :label="'Estimation Finish Time'" :selected_time="detail.estimation_finish" v-on:selected-time="(...args)=>this.setFinish([detail,...args])"/>
          </div>
          <div class="form-group col-sm-3 col-md-3 text-right">
            <button @click.prevent="removeTime(detail)" class="btn btn-danger">Remove</button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12" style="border-top: 1px solid black;margin-top:10px;padding-top:10px;">
        <div class="row">
          <h4 class="col-sm-12">Assigned Vehicle & Driver</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button @click.prevent="addClickVehicle()" class="btn btn-success">Add Vehicle & Driver</button>
          </div>
        </div>
        <div class="row" v-for="detail in trip_schedule.trip_schedule_vehicle_drivers" :key="detail">          
          <div class="form-group col-sm-4 required">
            <label for="vehicle_id">Vehicle:</label>
            <Select2 name="vehicle_id" required v-model.number="detail.vehicle_id" :options="vehicleOptions" />
          </div>
          <div class="form-group col-sm-4">
            <label for="driver_id">Driver:</label>
            <Select2 name="driver_id" v-model.number="detail.driver_id" :options="driverOptions" />
          </div>
          <div class="form-group col-sm-4 text-right">
            <button @click.prevent="removeVehicle(detail)" class="btn btn-danger">Remove</button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/trip_schedules">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    components:{
      TimeComponent,
      Select2,
    },
    computed: {
        ...mapState('trip_schedules', ['trip_schedule', 'onRequest']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
        trip_schedule_start_date:{
          get(){
            if(this.trip_schedule && this.trip_schedule.start_date){
              return moment(this.trip_schedule.start_date).format("YYYY-MM-DD")
            }
            return null
          },
          set(val){
            this.trip_schedule.start_date = val
          }
        },
        trip_schedule_finish_date:{
          get(){
            if(this.trip_schedule && this.trip_schedule.finish_date){
              return moment(this.trip_schedule.finish_date).format("YYYY-MM-DD")
            }
            return null
          },
          set(val){
            this.trip_schedule.finish_date = val
          }
        },
    },
    data(){
      return {        
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.trip_schedule.start_date = this.trip_schedule_start_date
        this.trip_schedule.finish_date = this.trip_schedule_finish_date
        this.edit(this.trip_schedule)
      },
      ...mapActions('trip_schedules', ['edit', 'get']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_route'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      addClick(){
        this.trip_schedule.trip_schedule_details.push({id:null, day: 1, estimation_start: null, estimation_finish: null})
      },
      addClickVehicle(){
        this.trip_schedule.trip_schedule_vehicle_drivers.push({vehicle_id:null, driver_id: null})
      },
      setStart(args){
        args[0].estimation_start = args[1]
      },
      setFinish(args){
        args[0].estimation_finish = args[1]
      },
      removeTime(detail){
        const index = this.trip_schedule.trip_schedule_details.indexOf(detail);
        if(index > -1){
          this.trip_schedule.trip_schedule_details.splice(index, 1)
        }
      },
      removeVehicle(detail){
        const index = this.trip_schedule.trip_schedule_vehicle_drivers.indexOf(detail);
        if(index > -1){
          this.trip_schedule.trip_schedule_vehicle_drivers.splice(index, 1)
        }
      },
    },
    created() {
      this.$emit('onChildInit', 'Edit Trip Schedule')
      this.get(this.$route.params.id)
    },
    watch:{
      trip_schedule(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.route_id){
            this.getVehicles({route_id : nextState.route_id})
          }
          if(nextState.account_id){
            this.getDrivers({account_id : nextState.account_id})
          }
          if(nextState.trip_schedule_details){
            nextState.trip_schedule_details.sort(function(a, b) {
              if(a.day < b.day){
                return -1
              } else if (a.day > b.day){
                return 1
              } else {
                return 0
              }
            })
          }
          return
        }
      },      
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="trip.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="trip.account.account_name">
      </div>
      <div v-if="trip.route" class="form-group col-sm-12">
          <label for="route_id">Route:</label>
          <input class="form-control" disabled name="route_id" type="text" v-model="trip.route.route_name">
      </div>
      <div v-if="trip.sub_route" class="form-group col-sm-12">
          <label for="route_id">Sub Route:</label>
          <input class="form-control" disabled name="route_id" type="text" v-model="trip.sub_route.sub_route_name">
      </div>
      <div class="form-group col-sm-12">
          <label for="trip_code">Trip Code:</label>
          <input class="form-control" disabled name="trip_code" type="text" v-model="trip.trip_code">
      </div>
      <div v-if="trip.vehicle" class="form-group col-sm-6">
          <label for="account_id">Vehicle:</label>
          <input class="form-control" disabled name="vehicle_id" type="text" :value="trip.vehicle.vehicle_no + ' - ' + trip.vehicle.vehicle_code">
      </div>
      <div class="form-group col-sm-6">
          <label for="account_id">Driver:</label>
          <input class="form-control" disabled name="driver_id" type="text" :value="trip.driver ? trip.driver.name : ''">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="trip_date">Trip Date:</label>
        <input class="form-control" name="trip_date" type="date" disabled v-model="trip_date">
      </div>
      <div class="form-group col-sm-12 col-md-12 ">
        <label for="road_warrant_id">Road Warrant:</label>
        <Select2 name="road_warrant_id" v-model.number="trip.road_warrant_id" :options="roadWarrantOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="estimation_trip_start_date">Estimation Start Date:</label>
        <input class="form-control" name="estimation_trip_start_date" type="date" disabled v-model="estimation_start_date">
      </div>
      <TimeComponent :label="'Estimation Start Time'" :selected_time="estimation_start_time" v-on:selected-time="setStart"/>
      <div class="form-group col-sm-12 col-md-6">
        <label for="estimation_trip_start_date">Estimation Finish Date:</label>
        <input class="form-control" name="estimation_trip_start_date" type="date" disabled v-model="estimation_finish_date">
      </div>
      <TimeComponent :label="'Estimation Finish Time'" :selected_time="estimation_finish_time" v-on:selected-time="setFinish"/>
      <div class="form-group col-sm-6 col-md-6 required">
        <label for="status">Total Penumpang:</label>
        <input class="form-control" name="total_penumpang" type="number" required v-model.number="trip.total_penumpang">
      </div>
      <div class="form-group col-sm-6 col-md-6 required">
        <label for="status">Penumpang Rit 1:</label>
        <input class="form-control" name="penumpang1" type="number" required v-model.number="trip.penumpang1">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="male">Male Passenger:</label>
        <input class="form-control" name="male" type="number" v-model.number="trip.male">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="female">Female Passenger:</label>
        <input class="form-control" name="female" type="number" v-model.number="trip.female">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="status">Total Revenue:</label>
        <input class="form-control" name="total_revenue" type="number" v-model.number="trip.total_revenue">
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <label for="status">Revenue Rit 1:</label>
        <input class="form-control" name="revenue1" type="number" v-model.number="trip.revenue1">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="status">Status:</label>
        <select class="form-control" name="status" disabled v-model.number="trip.status">
          <option value="0" style="color:black;">Draft</option>
          <option value="1" style="color:black;">Started</option>
          <option value="2" style="color:black;">Completed One Trip</option>
          <option value="3" style="color:black;">Completed</option>
          <option value="4" style="color:black;">Cancelled</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="start">Trip Start:</label>
        <input class="form-control" name="start" type="text" disabled v-model="trip_start">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="finish">Trip Finish:</label>
        <input class="form-control" name="finish" type="text" disabled v-model="trip_finish">
      </div>
      <InputImage label="Picture" v-on:selected-image="setImage"/>
      <div v-if="trip.pic_url" class="form-group col-sm-12">
        <img :src="trip.pic_url" height="200"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/trips">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import TimeComponent from '../components/TimeComponent.vue'
  import InputImage from '../components/InputImage.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    components:{
      TimeComponent,
      InputImage,
      Select2,
    },
    computed: {
        ...mapState('trips', ['trip', 'onRequest']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('road_warrants', ['road_warrants']),
        ...mapState('drivers', ['drivers']),
        trip_date:{
          get(){
            if(this.trip && this.trip.trip_date){
              return moment(this.trip.trip_date).format("YYYY-MM-DD")
            }
            return null
          },
          set(val){
            this.trip.trip_date = val
          }
        },
        trip_start(){
          if(this.trip && this.trip.start){
            return moment(this.trip.start).format("lll")
          }
          return ''
        },
        trip_finish(){
          if(this.trip && this.trip.finish){
            return moment(this.trip.finish).format("lll")
          }
          return ''
        },
    },
    data(){
      return {
        file_image: null,
        estimation_start_date: moment().format("YYYY-MM-DD"),
        estimation_finish_date: moment().format("YYYY-MM-DD"),
        estimation_start_time: '07:00',
        estimation_finish_time: '16:00',
        roadWarrantOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.trip.trip_date = this.trip_date
        this.trip.estimation_start = this.estimation_start_date + " " + this.estimation_start_time
        this.trip.estimation_finish = this.estimation_finish_date + " " + this.estimation_finish_time
        this.trip.file_image = this.file_image
        this.edit(this.trip)
      },
      ...mapActions('trips', ['edit', 'get']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_route'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      ...mapActions('road_warrants', {getDataByVehicle: 'get_road_warrant_by_vehicle'}),
      setStart(val){
        this.estimation_start_time = val
      },
      setFinish(val){
        this.estimation_finish_time = val
      },
      setImage(image){
        this.file_image = image
      },
    },
    created() {
      this.$emit('onChildInit', 'Edit Trip')
      this.get(this.$route.params.id)
    },
    watch:{
      trip(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.route_id){
            this.getVehicles({route_id : nextState.route_id})
          }
          if(nextState.account_id){
            this.getDrivers({account_id : nextState.account_id})
          }
          if(nextState.vehicle_id){
            this.getDataByVehicle({vehicle_id : nextState.vehicle_id, date: nextState.trip_date})
          }
          if(nextState.estimation_start){
            this.estimation_start_date = moment.utc(nextState.estimation_start).format("YYYY-MM-DD")
            this.estimation_start_time = moment.utc(nextState.estimation_start).format("HH:mm")
          }
          if(nextState.estimation_finish){
            this.estimation_finish_date = moment.utc(nextState.estimation_finish).format("YYYY-MM-DD")
            this.estimation_finish_time = moment.utc(nextState.estimation_finish).format("HH:mm")
          }
          return
        }
      },
      road_warrants(nextState, prevState){
        if(nextState !== prevState){
          this.roadWarrantOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.roadWarrantOptions.push({id:item.id, text:item.road_warrant_code})
            })
          }
          return
        }
      },
    }
  }
</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <GMapMap
          :center="center"
          :zoom="14"
          :disableDefaultUI="true"
          :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: true,
          }"
          ref="gmap"
          map-type-id="terrain"
          style="width: 100%; height: 50vh"
      >
        <GMapPolyline
          v-if="trip_history && trip_history.length > 0"
          :path="trip_history"
          :options="{ strokeColor:'#0000FF', strokeWeight: 2}"
          />
        <GMapMarker
          v-if="selectedData"
          :position="this.selectedData"
          ref="selectedMarker"
          :icon="this.icon"
          :clickable="false"
          :draggable="false"
        />
      </GMapMap>
    </div>
    <div class="col-sm-12 col-md-12" v-if="trip_history.length > 0">
      <div class="row">
        <div class="form-group col-sm-12">
          <button class="btn btn-success mt-3" @click="play()"><i class="fas fa-play"></i></button>
          <button class="btn btn-success mt-3 mx-3" @click="pause()"><i class="fas fa-pause"></i></button>
          <button class="btn btn-success mt-3" @click="stop()"><i class="fas fa-stop"></i></button>
          <select class="mx-3" name="speed_play" v-model.number="speed_play">
            <option value="1">x1</option>
            <option value="2">x2</option>
            <option value="3">X3</option>
            <option value="4">x4</option>
            <option value="5">x5</option>
            <option value="6">x6</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-bind:entries="trip_history" :columns="columns" :currEntries="100" :onRowClickEvent="onRowClicked" :title="title"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('trip_histories', ['trip_history', 'onRequest']),
        google: VueGoogleMaps,
        icon(){
          return {
            path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
            size: {width: 60, height: 90, f: 'px', b: 'px',},
            scale: 0.7,
            rotation: (this.selectedData ? parseInt(this.selectedData.course) : 0),
            fillColor: (this.selectedData ? this.getColor(this.selectedData.status) : ""),
            fillOpacity: 1,
            anchor: new window.google.maps.Point(15, 25),
          }
        },
        title(){
          if(this.vehicle_id !== null){
            let veh = this.vehicles.filter(item => item.id === this.vehicle_id)
            if(veh.length > 0){
              return 'Playback Vehicle - ' + veh[0].vehicle_no + ' - ' + this.from_date + ' ' + this.from_time +  ' - ' + this.to_date + ' ' + this.to_time
            }
          }
          return 'Playback Vehicle'
        }
    },
    data(){
      return {
        speed_play: 1,
        timer: null,
        played_idx: 0,
        columns: [
            {name: 'time', text: 'Time', order: 'desc',
              raw: {
                type: 'Moment_UTC',
              }
            },
            {name: 'status', text: 'Status',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Drive'},
                  {value: '2', text: 'Stop'},
                  {value: '5', text: 'Event'},
                ]
              }
            },
            {name: 'lat', text: 'Latitude'},
            {name: 'lng', text: 'Longitude'},
            {name: 'course', text: 'Course'},
            {name: 'speed', text: 'Speed'},
          ],
        center: {lat: -6.21462, lng: 106.84513},
        isFitOnce: false,
        selectedData: null,
        histories_moving: [],
        vehicleOptions: [],
        accountOptions: [],
      }
    },
    methods: {
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('trip_histories', ['get_data', 'clear_data']),
      onRowClicked(item){
        this.selectedData = item
        if(this.$refs.selectedMarker){
          this.$refs.selectedMarker.$markerObject.setPosition(item);
          this.$refs.gmap.$mapPromise.then(() => {
            this.$refs.gmap.panTo(this.$refs.selectedMarker.$markerObject.getPosition())
          })
        }
      },
      getColor(data){
        if(data.speed > 0){
          return '#3bb143'
        }
        return '#ef5350'
      },
      play(){
        clearInterval(this.timer)
        const self = this;
        this.timer = setInterval(function(){
          let item = self.histories_moving[self.played_idx]
          self.onRowClicked(item)
          if(self.played_idx < self.histories_moving.length){
            self.played_idx++
          }
        }, 1000 / self.speed_play)
      },
      pause(){
        clearInterval(this.timer)
      },
      stop(){
        this.played_idx = 0;
        this.selectedData = null
        clearInterval(this.timer)
      },
      getUtc(item){
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      }
    },
    created() {
      this.$emit('onChildInit', 'Playback')
      this.clear_data()
      this.get_data(this.$route.params.id)
    },
    unmounted(){
      clearInterval(this.timer)
    },
    components: {
      Datatable,
    },
    watch: {
      trip_history (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.length > 0){
            nextState.sort(function(a, b){
              if(a.device_time < b.device_time) return -1
              else return 1
            })
            if(!this.isFitOnce){
              this.$refs.gmap.$mapPromise.then(() => {
                let bounds = new window.google.maps.LatLngBounds()
                nextState.forEach(loc => {
                  bounds.extend(loc)
                })
                this.$nextTick().then(() => {
                  this.$refs.gmap.fitBounds(bounds)
                })
                this.$refs.gmap.panToBounds(bounds, 100)
              })
              this.isFitOnce = true
            }
            this.histories_moving = nextState.filter(item => item.speed > 0)
          }
          return
        }
      }
    },
  }
</script>

<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="trip_schedules" :columns="columns" :title="'Trips'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('trip_schedules', ['trip_schedules', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        let data = this.first_table
        if(getCapability('sub_routes', this.user)){
          data.push({name: 'sub_route', text: 'Sub Route',
            raw: {
              type: 'Sub',
              value: 'sub_route_name'
            }
          })
        }
        data = data.concat(this.second_table)
        data.push({name: 'action', text: 'Action',
          raw: {
            type: 'Action',
            fields: [
              getCapability('edit_trip_schedule', this.user) ? {event: 'edit_trip_schedule', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
              getCapability('delete_trip_schedule', this.user) ? {event: 'trip_schedules/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},              
            ]
          }
        })
        return data
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        first_table :[
          {name: 'start_date', text: 'Start Date', order: 'desc', raw:{type:'Moment_date'}},
          {name: 'finish_date', text: 'Finish Date', raw:{type:'Moment_date'}},
          {name: 'schedule_name', text: 'Schedule Name'},
          {name: 'route', text: 'Route',
            raw: {
              type: 'Sub',
              value: 'route_name'
            }
          },
          {name: 'account', text: 'Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
        ],
        second_table:[
          {name: 'created_at', text: 'Created', raw:{type:'Moment_UTC'}},
        ]
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('trip_schedules', ['get_trip_schedule_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id} = this
        this.get_trip_schedule_by_account({account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Trip Schedules', getCapability('add_trip_schedule', this.user) ? '/trip_schedules/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>

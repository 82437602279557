<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="account_id">Account:</label>
          <select class="form-control" name="account_id" v-model.number="u.account_id" required>
            <option v-for="acc in accounts" :key="acc" :value="acc.id">{{acc.account_name}}</option>
          </select>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="user_name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="u.name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_person">Email:</label>
        <input class="form-control" readonly="" name="email" type="email" v-model="u.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_phone">Phone:</label>
        <input class="form-control" name="phone" type="text" v-model="u.phone">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="u.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-danger mt-1" value="Reset Password" @click.prevent="reset_pass">
        <br/>
        <small>Reset Password to 12345678</small>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('users', ['u', 'onRequest']),
        ...mapState('accounts', ['accounts']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.u)
      },
      reset_pass(){
        if(this.onRequest) return;
        this.reset_password(this.u)
      },
      ...mapActions('users', ['edit', 'get', 'reset_password']),
      ...mapActions('accounts', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Edit User')
      this.get_all()
      this.get(this.$route.params.id)
    }
  }
</script>

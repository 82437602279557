<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="sub_route.account_id" :options="accountOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" required v-model.number="sub_route.route_id" :options="routeOptions"  @change="onChangeRoute($event)" @select="onChangeRoute($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
          <label for="sub_route_name">Sub Route Name:</label>
          <input class="form-control" required="" name="sub_route_name" type="text" v-model="sub_route.sub_route_name">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="sub_route_code">Sub Route Code:</label>
        <input class="form-control" required="" name="sub_route_code" type="text" v-model="sub_route.sub_route_code">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="sub_route_start">Location Start:</label>
        <Select2 name="route_start_id" v-model.number="sub_route.sub_route_start_id" :options="stopOptions" required />
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="sub_route_end">Location End:</label>
        <Select2 name="route_start_id" v-model.number="sub_route.sub_route_end_id" :options="stopOptions" required />
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="sub_route_distance">Distance (km):</label>
        <input class="form-control" required="" name="sub_route_distance" type="number" step="any" min="0" v-model.number="sub_route.sub_route_distance">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="sub_route.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/sub_routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('sub_routes', ['onRequest']),
        ...mapState('routes', ['routes']),
        ...mapState('stops', ['stops']),
    },
    data(){
      return {
        sub_route: {
          account_id: '',
          route_id: '',
          sub_route_name: '',
          sub_route_code: '',
          sub_route_start_id: '',
          sub_route_end_id: '',
          sub_route_distance: '',
          status: 1,
        },
        stopOptions: [],
        accountOptions: [],
        routeOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {sub_route} = this
        this.add(sub_route)
      },
      ...mapActions('sub_routes', ['add']),
      ...mapActions('routes', ['get_route_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('stops', {getStops: 'get_stop_by_route'}),
      onChange(event){
        this.get_route_by_account({account_id: parseInt(event.id)})
        this.sub_route.sub_route_start_id = ''
        this.sub_route.sub_route_end_id = ''
        this.sub_route.route_id = ''
      },
      onChangeRoute(event){
        this.getStops({route_id: parseInt(event.id)})
        this.sub_route.sub_route_start_id = ''
        this.sub_route.sub_route_end_id = ''
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Sub Route')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      stops(nextState, prevState){
        if(nextState !== prevState){
          this.stopOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.stopOptions.push({id:item.id, text:item.stop_name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name})
            })
          }
          return
        }
      }
    }
  }
</script>
